/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import React, { useCallback, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import CustomCombobox from "../Tools/CustomCombobox";
import {
    CreditCardIcon,
    UserCircleIcon,
    ViewGridAddIcon,
    CurrencyDollarIcon,
    OfficeBuildingIcon,
    ClipboardListIcon,
} from "@heroicons/react/outline";
import { ValidateBankDetails } from "../Tools/Validate";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export default function RepresentativeDetails(props) {
    const navigate = useNavigate();
    const handleNonprofitDetails = useCallback(
        () => navigate("/", { replace: true }),
        [navigate]
    );
    const handleNonprofitRepresentativeDetails = useCallback(
        () => navigate("/nonprofit-representative-details", { replace: true }),
        [navigate]
    );
    const handleDonationProcessingDetails = useCallback(
        () => navigate("/donation-processing-details", { replace: true }),
        [navigate]
    );
    const handleBankDetails = useCallback(
        () => navigate("/bank-details", { replace: true }),
        [navigate]
    );
    const handleSummary = useCallback(
        () => navigate("/summary", { replace: true }),
        [navigate]
    );

    const navigation = [
        {
            name: "Nonprofit Details",
            link: handleNonprofitDetails,
            icon: OfficeBuildingIcon,
            current: false,
        },
        {
            name: "Nonprofit Representative Details",
            link: handleNonprofitRepresentativeDetails,
            icon: UserCircleIcon,
            current: false,
        },
        {
            name: "Donation Processing Details",
            link: handleDonationProcessingDetails,
            icon: CreditCardIcon,
            current: false,
        },
        {
            name: "Bank Details",
            link: handleBankDetails,
            icon: CurrencyDollarIcon,
            current: true,
        },
        {
            name: "Summary",
            link: handleSummary,
            icon: ClipboardListIcon,
            current: false,
        },
    ];

    const [errorMessages, setErrorMessages] = useState([]);

    return (
        <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
            <aside className="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3">
                <nav className="space-y-1">
                    {navigation.map((item) => (
                        // <Link to={item.href} >
                        <a
                            key={item.name}
                            className={classNames(
                                item.current
                                    ? "bg-gray-50 text-indigo-700 hover:text-indigo-700 hover:bg-white"
                                    : "text-gray-900 hover:text-gray-900 hover:bg-gray-50",
                                "group rounded-md px-3 py-2 flex items-center text-sm font-medium"
                            )}
                            aria-current={item.current ? "page" : undefined}
                            onClick={(e) => {
                                e.preventDefault();
                                const errorMessages =
                                    ValidateBankDetails(props);
                                if (errorMessages.length == 0) {
                                    item.link();
                                } else {
                                    setErrorMessages(errorMessages);
                                    window.scrollTo({
                                        top: document.body.scrollHeight,
                                        behavior: "smooth",
                                    });
                                }
                            }}>
                            <item.icon
                                className={classNames(
                                    item.current
                                        ? "text-indigo-500 group-hover:text-indigo-500"
                                        : "text-gray-400 group-hover:text-gray-500",
                                    "flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                                )}
                                aria-hidden="true"
                            />
                            <span className="truncate">{item.name}</span>
                        </a>
                        // </Link>
                    ))}
                </nav>
            </aside>
            <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
                <form action="#" method="POST">
                    <div className="shadow sm:rounded-md sm:overflow-hidden">
                        <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
                            <div>
                                <h3 className="text-lg leading-6 font-medium text-gray-900">
                                    Finally, we'll need to know which Bank
                                    Account to send your donations to
                                </h3>
                                <p className="mt-1 text-sm text-gray-500">
                                    The information CharityStack collects about
                                    your nonprofit helps us meet requirements
                                    from regulators, financial partners, and our
                                    Services Agreement.
                                </p>
                                <p className="mt-1 text-sm text-gray-500">
                                    You agree to use this account for legitimate
                                    business purposes and not for personal,
                                    family, or household purposes.
                                </p>
                            </div>

                            <div className="grid grid-cols-5 gap-5">
                                <div className="col-span-5 sm:col-span-2">
                                    <label
                                        htmlFor="first-name"
                                        className="block text-sm font-medium text-gray-700">
                                        First Name
                                        <div className="inline text-sm font-medium text-red-700">
                                            &nbsp;*
                                        </div>
                                    </label>
                                    <input
                                        type="text"
                                        name="first-name"
                                        id="first-name"
                                        autoComplete="given-name"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        value={props.bankFirstName}
                                        onChange={(e) => {
                                            props.setBankFirstName(
                                                e.target.value
                                            );
                                        }}
                                    />
                                </div>

                                <div className="col-span-5 sm:col-span-2">
                                    <label
                                        htmlFor="last-name"
                                        className="block text-sm font-medium text-gray-700">
                                        Last Name
                                        <div className="inline text-sm font-medium text-red-700">
                                            &nbsp;*
                                        </div>
                                    </label>
                                    <input
                                        type="text"
                                        name="last-name"
                                        id="last-name"
                                        autoComplete="family-name"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        value={props.bankLastName}
                                        onChange={(e) => {
                                            props.setBankLastName(
                                                e.target.value
                                            );
                                        }}
                                    />
                                </div>

                                <div className="col-span-5 sm:col-span-3">
                                    <label
                                        htmlFor="account-number"
                                        className="block text-sm font-medium text-gray-700">
                                        Account Number
                                        <div className="inline text-sm font-medium text-red-700">
                                            &nbsp;*
                                        </div>
                                    </label>
                                    <input
                                        type="text"
                                        name="account-number"
                                        id="account-number"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        value={props.accountNumber}
                                        onChange={(e) => {
                                            // filter out any non-number characters
                                            e.target.value =
                                                e.target.value.replace(
                                                    /[^0-9]/g,
                                                    ""
                                                );
                                            props.setAccountNumber(
                                                e.target.value.slice(
                                                    0,
                                                    Math.min(
                                                        e.target.value.length,
                                                        17
                                                    )
                                                )
                                            );
                                        }}
                                    />
                                </div>

                                <div className="col-span-5 sm:col-span-3">
                                    <label
                                        htmlFor="routing-number"
                                        className="block text-sm font-medium text-gray-700">
                                        Routing Number
                                        <div className="inline text-sm font-medium text-red-700">
                                            &nbsp;*
                                        </div>
                                    </label>
                                    <input
                                        type="text"
                                        name="routing-number"
                                        id="routing-number"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        value={props.routingNumber}
                                        onChange={(e) => {
                                            // filter out any non-number characters
                                            e.target.value =
                                                e.target.value.replace(
                                                    /[^0-9]/g,
                                                    ""
                                                );
                                            props.setRoutingNumber(
                                                e.target.value.slice(
                                                    0,
                                                    Math.min(
                                                        e.target.value.length,
                                                        9
                                                    )
                                                )
                                            );
                                        }}
                                    />
                                </div>

                                {errorMessages.map((errorMessage) => {
                                    return (
                                        <div className="col-span-5 sm:col-span-3">
                                            <p
                                                htmlFor="nonprofit-description"
                                                className="block text-sm font-medium text-red-700">
                                                {errorMessage}
                                            </p>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                            <Link to="/summary">
                                <button
                                    type="submit"
                                    className="bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        const errorMessages =
                                            ValidateBankDetails(props);
                                        if (errorMessages.length == 0) {
                                            handleSummary();
                                        } else {
                                            setErrorMessages(errorMessages);
                                            window.scrollTo(
                                                0,
                                                document.body.scrollHeight
                                            );
                                        }
                                    }}>
                                    Continue
                                </button>
                            </Link>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}
