/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/

import {
    CreditCardIcon,
    UserCircleIcon,
    ViewGridAddIcon,
    CurrencyDollarIcon,
    OfficeBuildingIcon,
    ClipboardListIcon,
} from "@heroicons/react/outline";
import React, { useCallback, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Datepicker from "@themesberg/tailwind-datepicker/Datepicker";
import CustomCombobox from "../Tools/CustomCombobox";
import { ValidateNonprofitDetails } from "../Tools/Validate";

const states = [
    "AL",
    "AK",
    "AZ",
    "AR",
    "AS",
    "CA",
    "CO",
    "CT",
    "DE",
    "DC",
    "FL",
    "GA",
    "GU",
    "HI",
    "ID",
    "IL",
    "IN",
    "IA",
    "KS",
    "KY",
    "LA",
    "ME",
    "MD",
    "MA",
    "MI",
    "MN",
    "MS",
    "MO",
    "MT",
    "NE",
    "NV",
    "NH",
    "NJ",
    "NM",
    "NY",
    "NC",
    "ND",
    "CM",
    "OH",
    "OK",
    "OR",
    "PA",
    "PR",
    "RI",
    "SC",
    "SD",
    "TN",
    "TX",
    "TT",
    "UT",
    "VT",
    "VA",
    "VI",
    "WA",
    "WV",
    "WI",
    "WY",
];

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export default function NonprofitDetails(props) {
    const navigate = useNavigate();
    const handleNonprofitDetails = useCallback(
        () => navigate("/", { replace: true }),
        [navigate]
    );
    const handleNonprofitRepresentativeDetails = useCallback(
        () => navigate("/nonprofit-representative-details", { replace: true }),
        [navigate]
    );
    const handleDonationProcessingDetails = useCallback(
        () => navigate("/donation-processing-details", { replace: true }),
        [navigate]
    );
    const handleBankDetails = useCallback(
        () => navigate("/bank-details", { replace: true }),
        [navigate]
    );
    const handleSummary = useCallback(
        () => navigate("/summary", { replace: true }),
        [navigate]
    );

    const navigation = [
        {
            name: "Nonprofit Details",
            link: handleNonprofitDetails,
            icon: OfficeBuildingIcon,
            current: true,
        },
        {
            name: "Nonprofit Representative Details",
            link: handleNonprofitRepresentativeDetails,
            icon: UserCircleIcon,
            current: false,
        },
        {
            name: "Donation Processing Details",
            link: handleDonationProcessingDetails,
            icon: CreditCardIcon,
            current: false,
        },
        {
            name: "Bank Details",
            link: handleBankDetails,
            icon: CurrencyDollarIcon,
            current: false,
        },
        {
            name: "Summary",
            link: handleSummary,
            icon: ClipboardListIcon,
            current: false,
        },
    ];

    const [errorMessages, setErrorMessages] = useState([]);

    return (
        <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
            <aside className="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3">
                <nav className="space-y-1">
                    {navigation.map((item) => (
                        // <Link to={item.href} >
                        <a
                            key={item.name}
                            className={classNames(
                                item.current
                                    ? "bg-gray-50 text-indigo-700 hover:text-indigo-700 hover:bg-white"
                                    : "text-gray-900 hover:text-gray-900 hover:bg-gray-50",
                                "group rounded-md px-3 py-2 flex items-center text-sm font-medium"
                            )}
                            aria-current={item.current ? "page" : undefined}
                            onClick={(e) => {
                                e.preventDefault();
                                const errorMessages =
                                    ValidateNonprofitDetails(props);
                                if (errorMessages.length == 0) {
                                    item.link();
                                } else {
                                    setErrorMessages(errorMessages);
                                    window.scrollTo(
                                        0,
                                        document.body.scrollHeight
                                    );
                                }
                            }}>
                            <item.icon
                                className={classNames(
                                    item.current
                                        ? "text-indigo-500 group-hover:text-indigo-500"
                                        : "text-gray-400 group-hover:text-gray-500",
                                    "flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                                )}
                                aria-hidden="true"
                            />
                            <span className="truncate">{item.name}</span>
                        </a>
                        // </Link>
                    ))}
                </nav>
            </aside>
            <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
                <form action="#" method="POST">
                    <div className="shadow sm:rounded-md sm:overflow-hidden">
                        <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
                            <div>
                                <h3 className="text-lg leading-6 font-medium text-gray-900">
                                    Tell us about your Nonprofit
                                </h3>
                                <p className="mt-1 text-sm text-gray-500">
                                    The information CharityStack collects about
                                    your nonprofit helps us meet requirements
                                    from regulators, financial partners, and our
                                    Services Agreement.
                                </p>
                                <p className="mt-1 text-sm text-gray-500">
                                    The information you provide will be used to
                                    verify your identity. Additional information
                                    may be requested.
                                </p>
                            </div>

                            <div className="grid grid-cols-5 gap-5">
                                <div className="col-span-5 sm:col-span-3">
                                    <label
                                        htmlFor="nonprofit-name"
                                        className="block text-sm font-medium text-gray-700">
                                        Nonprofit Name
                                        <div className="inline text-sm font-medium text-red-700">
                                            &nbsp;*
                                        </div>
                                    </label>
                                    <input
                                        type="text"
                                        name="nonprofit-name"
                                        id="nonprofit-name"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        value={props.nonprofitName}
                                        onChange={(e) => {
                                            props.setNonprofitName(
                                                e.target.value
                                            );
                                        }}
                                    />
                                </div>

                                <div className="col-span-5 sm:col-span-3">
                                    <label
                                        htmlFor="tax-id"
                                        className="block text-sm font-medium text-gray-700">
                                        Tax ID/EIN
                                        <div className="inline text-sm font-medium text-red-700">
                                            &nbsp;*
                                        </div>
                                    </label>
                                    <input
                                        type="text"
                                        name="tax-id"
                                        id="tax-id"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        value={props.taxID}
                                        onChange={(e) => {
                                            // filter out any non-number characters
                                            e.target.value =
                                                e.target.value.replace(
                                                    /[^0-9]/g,
                                                    ""
                                                );
                                            props.setTaxID(
                                                e.target.value.slice(
                                                    0,
                                                    Math.min(
                                                        e.target.value.length,
                                                        9
                                                    )
                                                )
                                            );
                                        }}
                                    />
                                </div>

                                <div className="col-span-5 sm:col-span-3">
                                    <label
                                        htmlFor="website"
                                        className="block text-sm font-medium text-gray-700">
                                        Website
                                        <div className="inline text-sm font-medium text-red-700">
                                            &nbsp;*
                                        </div>
                                    </label>
                                    <input
                                        type="text"
                                        name="website"
                                        id="website"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        value={props.website}
                                        onChange={(e) => {
                                            props.setWebsite(e.target.value);
                                        }}
                                    />
                                </div>

                                <div className="col-span-5 sm:col-span-3">
                                    <label
                                        htmlFor="last-name"
                                        className="block text-sm font-medium text-gray-700">
                                        Incorporation Date
                                        <div className="inline text-sm font-medium text-red-700">
                                            &nbsp;*
                                        </div>
                                    </label>
                                    <input
                                        type="date"
                                        name="last-name"
                                        id="last-name"
                                        autoComplete="family-name"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        value={props.incorporationDate}
                                        onChange={(e) => {
                                            let value = e.target.value.match();
                                            props.setIncorporationDate(
                                                e.target.value
                                            );
                                        }}
                                    />
                                </div>

                                <div className="col-span-5 sm:col-span-3">
                                    <label
                                        htmlFor="address-line1"
                                        className="block text-sm font-medium text-gray-700">
                                        Address Line 1
                                        <div className="inline text-sm font-medium text-red-700">
                                            &nbsp;*
                                        </div>
                                    </label>
                                    <input
                                        type="text"
                                        name="address-line1"
                                        id="address-line1"
                                        autoComplete="address-line1"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        value={props.nonprofitAddressLine1}
                                        onChange={(e) => {
                                            props.setNonprofitAddressLine1(
                                                e.target.value
                                            );
                                        }}
                                    />
                                </div>

                                <div className="col-span-5 sm:col-span-3">
                                    <label
                                        htmlFor="address-line2"
                                        className="block text-sm font-medium text-gray-700">
                                        Address Line 2
                                    </label>
                                    <input
                                        type="text"
                                        name="address-line2"
                                        id="address-line2"
                                        autoComplete="address-line2"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        value={props.nonprofitAddressLine2}
                                        onChange={(e) => {
                                            props.setNonprofitAddressLine2(
                                                e.target.value
                                            );
                                        }}
                                    />
                                </div>

                                <div className="col-span-5 sm:col-span-3">
                                    <label
                                        htmlFor="city"
                                        className="block text-sm font-medium text-gray-700">
                                        City
                                        <div className="inline text-sm font-medium text-red-700">
                                            &nbsp;*
                                        </div>
                                    </label>
                                    <input
                                        type="text"
                                        name="city"
                                        id="city"
                                        autoComplete="address-level2"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        value={props.nonprofitCity}
                                        onChange={(e) => {
                                            props.setNonprofitCity(
                                                e.target.value
                                            );
                                        }}
                                    />
                                </div>

                                <div className="col-span-5 sm:col-span-3 grid">
                                    <label
                                        htmlFor="region"
                                        className="block text-sm font-medium text-gray-700">
                                        State
                                        <div className="inline text-sm font-medium text-red-700">
                                            &nbsp;*
                                        </div>
                                    </label>
                                    <CustomCombobox
                                        id="region"
                                        items={states}
                                        selected={props.nonprofitState}
                                        setSelected={props.setNonprofitState}
                                    />
                                </div>

                                <div className="col-span-5 sm:col-span-3">
                                    <label
                                        htmlFor="postal-code"
                                        className="block text-sm font-medium text-gray-700">
                                        Zip Code
                                        <div className="inline text-sm font-medium text-red-700">
                                            &nbsp;*
                                        </div>
                                    </label>
                                    <input
                                        type="text"
                                        name="postal-code"
                                        id="postal-code"
                                        autoComplete="postal-code"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        value={props.nonprofitZip}
                                        onChange={(e) => {
                                            // filter out any non-number characters
                                            e.target.value =
                                                e.target.value.replace(
                                                    /[^0-9]/g,
                                                    ""
                                                );
                                            props.setNonprofitZip(
                                                e.target.value.slice(
                                                    0,
                                                    Math.min(
                                                        e.target.value.length,
                                                        7
                                                    )
                                                )
                                            );
                                        }}
                                    />
                                </div>

                                <div className="row-span-3 col-span-5 sm:col-span-3">
                                    <label
                                        htmlFor="nonprofit-description"
                                        className="block text-sm font-medium text-gray-700">
                                        Nonprofit Description
                                        <div className="inline text-sm font-medium text-red-700">
                                            &nbsp;*
                                        </div>
                                    </label>
                                    <textarea
                                        type="text"
                                        name="nonprofit-description"
                                        id="nonprofit-description"
                                        className="h-40 mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        value={props.description}
                                        onChange={(e) => {
                                            props.setDescription(
                                                e.target.value
                                            );
                                        }}
                                    />
                                </div>

                                {errorMessages.map((errorMessage) => {
                                    return (
                                        <div className="col-span-5 sm:col-span-3">
                                            <p
                                                htmlFor="nonprofit-description"
                                                className="block text-sm font-medium text-red-700">
                                                {errorMessage}
                                            </p>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                            <Link to="/nonprofit-representative-details">
                                <button
                                    type="submit"
                                    className="bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        const errorMessages =
                                            ValidateNonprofitDetails(props);
                                        if (errorMessages.length == 0) {
                                            handleNonprofitRepresentativeDetails();
                                        } else {
                                            setErrorMessages(errorMessages);
                                            window.scrollTo({
                                                top: document.body.scrollHeight,
                                                behavior: "smooth",
                                            });
                                        }
                                    }}>
                                    Continue
                                </button>
                            </Link>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}
