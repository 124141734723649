/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import React, { useCallback, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
    CreditCardIcon,
    UserCircleIcon,
    ViewGridAddIcon,
    CurrencyDollarIcon,
    OfficeBuildingIcon,
    ClipboardListIcon,
} from "@heroicons/react/outline";
import { ValidateDonationProcessingDetails } from "../Tools/Validate";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export default function DonationProcessingDetails(props) {
    const navigate = useNavigate();
    const handleNonprofitDetails = useCallback(
        () => navigate("/", { replace: true }),
        [navigate]
    );
    const handleNonprofitRepresentativeDetails = useCallback(
        () => navigate("/nonprofit-representative-details", { replace: true }),
        [navigate]
    );
    const handleDonationProcessingDetails = useCallback(
        () => navigate("/donation-processing-details", { replace: true }),
        [navigate]
    );
    const handleBankDetails = useCallback(
        () => navigate("/bank-details", { replace: true }),
        [navigate]
    );
    const handleSummary = useCallback(
        () => navigate("/summary", { replace: true }),
        [navigate]
    );

    const navigation = [
        {
            name: "Nonprofit Details",
            link: handleNonprofitDetails,
            icon: OfficeBuildingIcon,
            current: false,
        },
        {
            name: "Nonprofit Representative Details",
            link: handleNonprofitRepresentativeDetails,
            icon: UserCircleIcon,
            current: false,
        },
        {
            name: "Donation Processing Details",
            link: handleDonationProcessingDetails,
            icon: CreditCardIcon,
            current: true,
        },
        {
            name: "Bank Details",
            link: handleBankDetails,
            icon: CurrencyDollarIcon,
            current: false,
        },
        {
            name: "Summary",
            link: handleSummary,
            icon: ClipboardListIcon,
            current: false,
        },
    ];

    const [errorMessages, setErrorMessages] = useState([]);
    return (
        <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
            <aside className="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3">
                <nav className="space-y-1">
                    {navigation.map((item) => (
                        // <Link to={item.href} >
                        <a
                            key={item.name}
                            className={classNames(
                                item.current
                                    ? "bg-gray-50 text-indigo-700 hover:text-indigo-700 hover:bg-white"
                                    : "text-gray-900 hover:text-gray-900 hover:bg-gray-50",
                                "group rounded-md px-3 py-2 flex items-center text-sm font-medium"
                            )}
                            aria-current={item.current ? "page" : undefined}
                            onClick={(e) => {
                                e.preventDefault();
                                const errorMessages =
                                    ValidateDonationProcessingDetails(props);
                                if (errorMessages.length == 0) {
                                    item.link();
                                } else {
                                    setErrorMessages(errorMessages);
                                    window.scrollTo(
                                        0,
                                        document.body.scrollHeight
                                    );
                                }
                            }}>
                            <item.icon
                                className={classNames(
                                    item.current
                                        ? "text-indigo-500 group-hover:text-indigo-500"
                                        : "text-gray-400 group-hover:text-gray-500",
                                    "flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                                )}
                                aria-hidden="true"
                            />
                            <span className="truncate">{item.name}</span>
                        </a>
                        // </Link>
                    ))}
                </nav>
            </aside>
            <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
                <form action="#" method="POST">
                    <div className="shadow sm:rounded-md sm:overflow-hidden">
                        <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
                            <div>
                                <h3 className="text-lg leading-6 font-medium text-gray-900">
                                    We'll also need some info regarding Donation
                                    Processing
                                </h3>
                                <p className="mt-1 text-sm text-gray-500">
                                    The information CharityStack collects about
                                    your nonprofit helps us meet requirements
                                    from regulators, financial partners, and our
                                    Services Agreement.
                                </p>
                            </div>

                            <div className="grid grid-cols-5 gap-5">
                                <div className="col-span-5 sm:col-span-3">
                                    <label
                                        htmlFor="default-statement-descriptor"
                                        className="block text-sm font-medium text-gray-700">
                                        Default Statement Descriptor
                                        <div className="inline text-sm font-medium text-red-700">
                                            &nbsp;*
                                        </div>
                                    </label>
                                    <input
                                        type="text"
                                        name="default-statement-descriptor"
                                        id="default-statement-descriptor"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        value={props.statementDescriptor}
                                        onChange={(e) => {
                                            props.setStatementDescriptor(
                                                e.target.value
                                            );
                                        }}
                                    />
                                </div>

                                <div className="col-span-5 sm:col-span-3">
                                    <label
                                        htmlFor="annual-card-volume"
                                        className="block text-sm font-medium text-gray-700">
                                        Annual Card Volume ($)
                                        <div className="inline text-sm font-medium text-red-700">
                                            &nbsp;*
                                        </div>
                                    </label>
                                    <input
                                        type="text"
                                        name="annual-card-volume"
                                        id="annual-card-volume"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        value={props.cardVolume}
                                        onChange={(e) => {
                                            // filter out any non-number characters
                                            e.target.value =
                                                e.target.value.replace(
                                                    /[^0-9]/g,
                                                    ""
                                                );
                                            props.setCardVolume(
                                                e.target.value.slice(
                                                    0,
                                                    Math.min(
                                                        e.target.value.length,
                                                        17
                                                    )
                                                )
                                            );
                                        }}
                                    />
                                </div>
                                <div className="col-span-5 sm:col-span-3">
                                    <label
                                        htmlFor="average-ach-transfer-amount"
                                        className="block text-sm font-medium text-gray-700">
                                        Average Card Transaction Amount ($)
                                        <div className="inline text-sm font-medium text-red-700">
                                            &nbsp;*
                                        </div>
                                    </label>
                                    <input
                                        type="text"
                                        name="average-ach-transfer-amount"
                                        id="average-ach-transfer-amount"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        value={props.averageCardAmount}
                                        onChange={(e) => {
                                            // filter out any non-number characters
                                            e.target.value =
                                                e.target.value.replace(
                                                    /[^0-9]/g,
                                                    ""
                                                );
                                            props.setAverageCardAmount(
                                                e.target.value.slice(
                                                    0,
                                                    Math.min(
                                                        e.target.value.length,
                                                        8
                                                    )
                                                )
                                            );
                                        }}
                                    />
                                </div>
                                <div className="col-span-5 sm:col-span-3">
                                    <label
                                        htmlFor="max-transaction-amount"
                                        className="block text-sm font-medium text-gray-700">
                                        Max Transaction Amount ($)
                                        <div className="inline text-sm font-medium text-red-700">
                                            &nbsp;*
                                        </div>
                                    </label>
                                    <input
                                        type="text"
                                        name="max-transaction-amount"
                                        id="max-transaction-amount"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        value={props.maxTransactionAmount}
                                        onChange={(e) => {
                                            // filter out any non-number characters
                                            e.target.value =
                                                e.target.value.replace(
                                                    /[^0-9]/g,
                                                    ""
                                                );
                                            props.setMaxTransactionAmount(
                                                e.target.value.slice(
                                                    0,
                                                    Math.min(
                                                        e.target.value.length,
                                                        10
                                                    )
                                                )
                                            );
                                        }}
                                    />
                                </div>

                                <div className="col-span-5 sm:col-span-3">
                                    <label
                                        htmlFor="annual-ach-volume"
                                        className="block text-sm font-medium text-gray-700">
                                        Annual ACH Volume ($)
                                        <div className="inline text-sm font-medium text-red-700">
                                            &nbsp;*
                                        </div>
                                    </label>
                                    <input
                                        type="text"
                                        name="annual-ach-volume"
                                        id="annual-ach-volume"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        value={props.achVolume}
                                        onChange={(e) => {
                                            // filter out any non-number characters
                                            e.target.value =
                                                e.target.value.replace(
                                                    /[^0-9]/g,
                                                    ""
                                                );
                                            props.setACHVolume(
                                                e.target.value.slice(
                                                    0,
                                                    Math.min(
                                                        e.target.value.length,
                                                        8
                                                    )
                                                )
                                            );
                                        }}
                                    />
                                </div>

                                <div className="col-span-5 sm:col-span-3">
                                    <label
                                        htmlFor="average-ach-transfer-amount"
                                        className="block text-sm font-medium text-gray-700">
                                        Average ACH Transfer Amount ($)
                                        <div className="inline text-sm font-medium text-red-700">
                                            &nbsp;*
                                        </div>
                                    </label>
                                    <input
                                        type="text"
                                        name="average-ach-transfer-amount"
                                        id="average-ach-transfer-amount"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        value={props.averageACHAmount}
                                        onChange={(e) => {
                                            // filter out any non-number characters
                                            e.target.value =
                                                e.target.value.replace(
                                                    /[^0-9]/g,
                                                    ""
                                                );
                                            props.setAverageACHAmount(
                                                e.target.value.slice(
                                                    0,
                                                    Math.min(
                                                        e.target.value.length,
                                                        8
                                                    )
                                                )
                                            );
                                        }}
                                    />
                                </div>
                                {errorMessages.map((errorMessage) => {
                                    return (
                                        <div className="col-span-5 sm:col-span-3">
                                            <p
                                                htmlFor="nonprofit-description"
                                                className="block text-sm font-medium text-red-700">
                                                {errorMessage}
                                            </p>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                            <Link to="/bank-details">
                                <button
                                    type="submit"
                                    className="bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        const errorMessages =
                                            ValidateDonationProcessingDetails(
                                                props
                                            );
                                        if (errorMessages.length == 0) {
                                            handleBankDetails();
                                        } else {
                                            setErrorMessages(errorMessages);
                                            window.scrollTo({
                                                top: document.body.scrollHeight,
                                                behavior: "smooth",
                                            });
                                        }
                                    }}>
                                    Continue
                                </button>
                            </Link>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}
